<template>
  <v-dialog persistent max-width="350" v-model="dialog">
    <v-card rounded>
      <v-card-title
        class="d-flex justify-space-between align-center primary white--text"
      >
        <div class="">Edit Material</div>
        <v-btn icon small @click="$emit('close')">
          <v-icon color="white" size="18">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="addForm" @submit.prevent="save" class="mt-5 mx-2">
          <label class="caption">MATERIAL TYPE *</label>
          <v-select
            :error-messages="errors.type"
            solo
            v-model="form.type"
            v-on:input="material($event)"
            :items="$helpers.material()"
          />

          <div v-if="selected.material">
            <label class="caption">{{ selected.title }}</label>
            <v-text-field
              :error-messages="errors.material"
              v-if="selected.material == 'url'"
              solo
              v-model="form.material"
            />
            <v-file-input
              :error-messages="errors.material"
              v-else
              solo
              v-model="form.material"
            />
          </div>

          <v-btn
            outlined
            block
            color="primary"
            :type="!loading ? 'submit' : ''"
            class="mt-2"
            >{{ !loading ? "Submit" : "Submitting..." }}</v-btn
          >
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["dialog", "topic"],
  data: () => ({
    loading: false,
    form: {
      type: "",
      material: "",
      lesson_id: "",
      course_id: "",
    },
    selected: {
      title: "",
      material: "",
    },
  }),
  computed: {
    ...mapState("teacher", {
      errors: (state) => state.errors,
    }),
  },
  methods: {
    material(e) {
      if (e == "u") {
        (this.selected.title = "YOUTUBE URL"), (this.selected.material = "url");
        return;
      }
      if (e == "t") {
        this.selected.title = "VIRTUAL TOUR";
        this.selected.material = "url";
        return;
      }
      if (e == "v") {
        (this.selected.title = "VIDEO"), (this.selected.material = "file");
        return;
      }
      if (e == "d") {
        (this.selected.title = "PDF/DOCx/PPT"),
          (this.selected.material = "file");
        return;
      }
    },
    save() {
      let form = new FormData();
      form.append("topic_id", this.topic.id);
      form.append("type", this.form.type);
      form.append("material", this.form.material);

      this.loading = true;
      this.$store
        .dispatch("teacher/updateMaterialAction", {
          form: form,
          uuid: this.topic.uuid,
        })
        .then(() => {
          this.loading = false;
          if (this.$errors("teacher")) {
            console.log("error");
            return;
          }
          this.$emit("save");
          this.$emit("close");
          setTimeout(() => {
            location.reload();
          }, 1000);
        });
    },
  },
};
</script>