<template>
  <section id="topic">
    <v-row v-if="!loading">
      <v-col cols="12" xl="8" lg="10">
        <v-card>
          <v-card-title class="d-flex align-center" style="height: 80px">
            <v-btn large icon class="mr-3" @click="$router.back()">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <div class="text-h6">
              {{ topic.title }}
            </div>
          </v-card-title>
          <v-divider />
          <v-row no-gutters>
            <v-col cols="12" lg="8">
              <div class="ma-3">
                <!--youtube -->
                <url
                  v-if="topic.type == 'u'"
                  :url="topic.material.replace('watch?v=', 'embed/')"
                />
                <!--videos -->
                <vid
                  v-if="topic.type == 'v'"
                  :file="`${topic.aws}/courses/${topic.lesson.course.uuid}/${topic.lesson.uuid}/${topic.uuid}/video/converted/${topic.material}_1080p.mp4`"
                />
                <!--docx -->
                <document v-if="topic.type == 'd'" :file="topic.document" />
                <!--virtual tour -->
                <url v-if="topic.type == 't'" :url="topic.material" />
                <!-- -->
              </div>
              <v-card-text>
                <v-row>
                  <v-col cols="12" xl="6">
                    <v-checkbox
                      color="primary"
                      dense
                      label="Enable Topic"
                      v-model="topic.status"
                    />

                    <label class="caption">TITLE</label>
                    <v-text-field solo v-model="topic.title" />

                    <v-btn color="primary" @click="details"
                      >Update Details</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
            <v-col cols="12" lg="4" class="secondary">
              <v-card elevation="0" color="secondary">
                <v-card-text>
                  <v-row justify="center" align="center" class="mr-2">
                    <v-col cols="6">
                      <v-btn @click="edit = true" block outlined color="primary"
                        >Edit Material</v-btn
                      >
                    </v-col>
                    <v-col cols="6">
                      <v-btn @click="dialog = true" color="danger" dark>
                        <v-icon left>mdi-plus</v-icon>
                        Assessment
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-divider class="my-5" />
                  <v-card
                    class="my-3"
                    v-for="item in topic.assessments"
                    :key="item.id"
                  >
                    <v-card-text>
                      <div class="body-1 black--text">
                        <b>{{ item.title }}</b>
                      </div>
                    </v-card-text>
                    <v-divider />
                    <v-card-actions
                      class="d-flex justify-end"
                      style="height: 40px"
                    >
                      <v-btn
                        :to="{
                          name: 'Teacher Assessment',
                          params: { uuid: item.uuid },
                        }"
                        color="primary"
                        icon
                      >
                        <v-icon>mdi-open-in-new</v-icon>
                      </v-btn>
                      <v-btn @click="remove(item.id)" icon color="red">
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <AddAssessment
      :dialog="dialog"
      @snackbar="showSnack"
      action="Topic"
      @close="dialog = !dialog"
    />

    <snackbar
      :snack="snack"
      :text="text"
      timeout="2000"
      @close="snack = false"
    />

    <EditMaterial
      :dialog="edit"
      :topic="topic"
      @save="
        snack = true;
        text = 'Material Updated';
      "
      @close="edit = !edit"
    />
  </section>
</template>

<script>
import { mapState } from "vuex";
import EditMaterial from "../components/material/Edit.vue";
import AddAssessment from "../components/assessment/AddAssessment.vue";

export default {
  components: {
    AddAssessment,
    EditMaterial,
  },
  data: () => ({
    loading: true,
    dialog: false,
    snack: false,
    text: "",
    edit: false,
  }),
  computed: {
    ...mapState("teacher", {
      topic: (state) => state.topic,
    }),
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$store
        .dispatch("teacher/showTopicAction", this.$route.params.uuid)
        .then(() => {
          this.loading = false;
        });
    },
    remove(id) {
      console.log(id);
      if (confirm("Are you sure you want to remove this data?")) {
        this.$store.dispatch("teacher/deleteAssessmentAction", id).then(() => {
          this.fetch();
        });
      }
    },
    details() {
      console.log(this.topic);
      this.$store.dispatch("teacher/updateTopicAction", this.topic).then(() => {
        if (this.$store.getters["teacher/errorsGetters"].length > 0) return;

        this.snack = true;
        this.text = "Details Updated";
      });
    },
    showSnack() {
      this.fetch();
      this.snack = true;
      this.text = "Assessment Saved";
    },
  },
};
</script>

<style>
</style>